
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import Multiselect from "@vueform/multiselect";
import ReportService from "@/core/services/car/ReportService";
import UserService from "@/core/services/UserService";
import MasterService from "@/core/services/car/MasterService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import RecordSelect from "../components/recordSelect.vue";
import { getMonthDate, getYearDate } from "@/store/stateless/store";
import { getAdminDashboardHeaders } from "@/store/stateless/store";

import IMultiselect from "@/models/IMultiselect";
interface IPayload {
  start_date: any;
  end_date: any;
}

export default defineComponent({
  // component name
  name: "admin-report",
  components: {
    // RecordSelect
    Multiselect,
  },
  data() {
    // constants
    return {
      shortcuts: [
        {
          text: "Last week",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })(),
        },
        {
          text: "Last month",
          value: (() => {
            const end = new Date();
            //end.setDate(1);
            //end.setHours(-1);
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })(),
        },
        {
          text: "Last 3 months",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })(),
        },
      ],
    };
  },
  setup() {
    const store = useStore();
    const value2 = ref<Array<string>>([]);

    let bodyPayloads = ref<IPayload>({
      start_date: moment().startOf("year").format("YYYY-MM-DD"),
      end_date: moment().endOf("month").format("YYYY-MM-DD"),
    });
    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });

    onMounted(() => {
      value2.value = getYearDate.currentYear();
      setCurrentPageBreadcrumbs("Admin Report", ["Reports"]);
      getAdminReport();
    });
    const year = new Date().getFullYear();
    let yearsList = computed(() => {
      return Array.from(
        { length: year - 2017 },
        (value, index) => year - index
      );
    });
    let years = ref<IMultiselect>({
      placeholder: "Select Year",
      value: yearsList.value[0],
      options: yearsList,
      searchable: true,
      clearOnSelect: true,
    });
    const onYearSelect = (event) => {
      let firstDay = new Date(event, 1 - 1);
      let lastDay = new Date(event, 12, 0);
      value2.value = []
      setDateRange(firstDay, lastDay);
      getAdminReport();
    };
    const setDateRange = (firstDay, lastDay) => {
      bodyPayloads.value.start_date = moment(firstDay).format("YYYY-MM-DD");
      bodyPayloads.value.end_date = moment(lastDay).format("YYYY-MM-DD");
    };
    const getAdminReport = () => {
      ReportService.getAdminReport(bodyPayloads.value);
    };
    const searchByDate = (listPeriod) => {
      if(listPeriod) {
        var startTS = new Date(listPeriod[0]);
        var endTS = new Date(listPeriod[1]);
        years.value.value = null
        bodyPayloads.value.start_date = moment(startTS.getFullYear() + "-" + (startTS.getMonth() + 1) + "-" + startTS.getDate()).format("YYYY-MM-DD");
        bodyPayloads.value.end_date = moment(endTS.getFullYear() + "-" + (endTS.getMonth() + 1) + "-" + endTS.getDate()).format("YYYY-MM-DD");
      } else {
        bodyPayloads.value.start_date = '';
        bodyPayloads.value.end_date = '';
      }

      getAdminReport();
    };
    let exportUrl = ref<string>("");
    const exportReport = () => {
      debugger;
      Promise.all([ReportService.exportAdminReport(bodyPayloads.value)]).then(
        (data) => {
          let exportResult = computed(() => {
            return store.getters.getAdminDashboardExportUrl;
          });
          exportUrl.value = exportResult.value;
        }
      );
    };
    let adminDashboardData = computed(() => {
      const data = store.getters.getAdminDashboardData;
      if(data && data.length > 0)
      {
        const filtered = data.filter(x => x.name !== 'total')
      
        const total_sale = (calculateTotal(filtered, 'total_sale'))
        const total_leads = calculateTotal(filtered, 'total_leads')
        const total_closing_ratio = roundUp((total_sale / total_leads) * 100).toFixed(2)
        const new_sale = calculateTotal(filtered, 'new_sale').toFixed(2)
        const new_leads = calculateTotal(filtered, 'new_leads').toFixed(2)
        const new_closing_ratio = roundUp((new_sale / new_leads) * 100).toFixed(2)
        const renewal_sale = calculateTotal(filtered, 'renewal_sale').toFixed(2)
        const renewal_leads = calculateTotal(filtered, 'renewal_leads').toFixed(2)
        const renewal_closing_ratio = roundUp((renewal_sale / renewal_leads) * 100).toFixed(2)
        const llr_sale = calculateTotal(filtered, 'llr_sale').toFixed(2)
        const llr_leads = calculateTotal(filtered, 'llr_leads')
        const llr_closing_ratio = roundUp((llr_sale / llr_leads) * 100).toFixed(2)
        const lrl_sale = calculateTotal(filtered, 'lrl_sale').toFixed(2)
        const lrl_leads = calculateTotal(filtered, 'lrl_leads').toFixed(2)
        const lrl_closing_ratio = roundUp((lrl_sale / lrl_leads) * 100).toFixed(2)
        const ltr_sale = calculateTotal(filtered, 'ltr_sale').toFixed(2)
        const ltr_leads = calculateTotal(filtered, 'ltr_leads').toFixed(2)
        const ltr_closing_ratio = roundUp((ltr_sale / ltr_leads) * 100).toFixed(2)
        const wip_sale = calculateTotal(filtered, 'wip_sale').toFixed(2)
        const wip_new = calculateTotal(filtered, 'wip_new').toFixed(2)
        const wip_renewal = calculateTotal(filtered, 'wip_renewal').toFixed(2)
        const wip_lrwl = calculateTotal(filtered, 'wip_lrwl').toFixed(2)
        const wip_others = calculateTotal(filtered, 'wip_others').toFixed(2)
        filtered.push({
          name: 'Total',
          total_sale,
          total_leads,
          total_closing_ratio: isNaN(parseFloat(total_closing_ratio)) ? 0 : total_closing_ratio,
          total_comp: calculateTotal(filtered, 'total_comp').toFixed(2),
          total_tpl: calculateTotal(filtered, 'total_tpl').toFixed(2),
          total_revenue: calculateTotal(filtered, 'total_revenue').toFixed(2),
          new_sale,
          new_leads,
          new_closing_ratio: isNaN(parseFloat(new_closing_ratio)) ? 0 : new_closing_ratio,
          new_comp: calculateTotal(filtered, 'new_comp').toFixed(2),
          new_tpl: calculateTotal(filtered, 'new_tpl').toFixed(2),
          new_revenue: calculateTotal(filtered, 'new_revenue').toFixed(2),
          wip_sale,
          wip_new,
          wip_renewal,
          wip_lrwl,
          wip_others,
          wip_comp: calculateTotal(filtered, 'wip_comp').toFixed(2),
          wip_tpl: calculateTotal(filtered, 'wip_tpl').toFixed(2),
          wip_revenue: calculateTotal(filtered, 'wip_revenue').toFixed(2),
          renewal_sale,
          renewal_leads,
          renewal_closing_ratio: isNaN(parseFloat(renewal_closing_ratio)) ? 0 : renewal_closing_ratio,
          renewal_comp: calculateTotal(filtered, 'renewal_comp').toFixed(2),
          renewal_tpl: calculateTotal(filtered, 'renewal_tpl').toFixed(2),
          renewal_revenue: calculateTotal(filtered, 'renewal_revenue').toFixed(2),
          llr_sale,
          llr_leads,
          llr_closing_ratio: isNaN(parseFloat(llr_closing_ratio)) ? 0 : llr_closing_ratio,
          llr_comp: calculateTotal(filtered, 'llr_comp').toFixed(2),
          llr_tpl: calculateTotal(filtered, 'llr_tpl').toFixed(2),
          llr_revenue: calculateTotal(filtered, 'llr_revenue').toFixed(2),
          lrl_sale,
          lrl_leads,
          lrl_closing_ratio: isNaN(parseFloat(lrl_closing_ratio)) ? 0 : lrl_closing_ratio,
          lrl_comp: calculateTotal(filtered, 'lrl_comp').toFixed(2),
          lrl_tpl: calculateTotal(filtered, 'lrl_tpl').toFixed(2),
          lrl_revenue: calculateTotal(filtered, 'lrl_revenue').toFixed(2),
          ltr_sale,
          ltr_leads,
          ltr_closing_ratio: isNaN(parseFloat(ltr_closing_ratio)) ? 0 : ltr_closing_ratio,
          ltr_comp: calculateTotal(filtered, 'ltr_comp').toFixed(2),
          ltr_tpl: calculateTotal(filtered, 'ltr_tpl').toFixed(2),
          lost_leads: calculateTotal(filtered, 'lost_leads').toFixed(2),
          ltr_revenue: calculateTotal(filtered, 'ltr_revenue').toFixed(2),
          untouched_new_leads: calculateTotal(filtered, 'untouched_new_leads').toFixed(2),
          untouched_renewal_leads: calculateTotal(filtered, 'untouched_renewal_leads').toFixed(2),
          untouched_llr: calculateTotal(filtered, 'untouched_llr').toFixed(2),
          work_in_progress: calculateTotal(filtered, 'work_in_progress').toFixed(2),
          hot_lead: calculateTotal(filtered, 'hot_lead').toFixed(2),
          return_hot_lead: calculateTotal(filtered, 'return_hot_lead').toFixed(2),
          untouched_customers: calculateTotal(filtered, 'untouched_customers').toFixed(2),
          full_refund: calculateTotal(filtered, 'full_refund').toFixed(2),
          total_refunds: calculateTotal(filtered, 'total_refunds').toFixed(2),
          commission_paid_to_agent: calculateTotal(filtered, 'commission_paid_to_agent').toFixed(2),
          partial_refund: calculateTotal(filtered, 'partial_refund').toFixed(2),
          total_refund: calculateTotal(filtered, 'total_refund').toFixed(2),
          partial_refund_amount: calculateTotal(filtered, 'partial_refund_amount').toFixed(2),
          full_refund_amount: calculateTotal(filtered, 'full_refund_amount').toFixed(2),
          total_refund_amount: calculateTotal(filtered, 'total_refund_amount').toFixed(2),
          total_revenue_inc_addons: calculateTotal(filtered, 'total_revenue_inc_addons').toFixed(2),
          addons: calculateTotal(filtered, 'addons').toFixed(2),
          total_cancellation_amount: calculateTotal(filtered, 'total_cancellation_amount').toFixed(2),
          total_cancellation: calculateTotal(filtered, 'total_cancellation').toFixed(2),
          partial_cancellation_amount: calculateTotal(filtered, 'partial_cancellation_amount').toFixed(2),
          partial_cancellation: calculateTotal(filtered, 'partial_cancellation').toFixed(2),
          full_cancellation_amount: calculateTotal(filtered, 'full_cancellation_amount').toFixed(2),
          full_cancellation: calculateTotal(filtered, 'full_cancellation').toFixed(2)
        })
        return filtered 
      }
      return data;
      
    });

    const calculateTotal = (data, column) => {
            return data.reduce((total, item) => {
                    if(typeof item[column] === 'string') {
                        return total += parseFloat(item[column])
                    } else {
                        return total += item[column]
                    }
            }, 0)
        }

        const roundUp = (num) => {
            var p = Math.pow(10, 2);
            return Math.round(num * p) / p;
        }

    const resetBodyParam = () => {
      value2.value = getMonthDate.currentMonth();
      bodyPayloads.value.start_date = moment().startOf("year").format("YYYY-MM-DD");
      bodyPayloads.value.end_date = moment().endOf("month").format("YYYY-MM-DD");
      exportUrl.value = "";
      years.value.value = yearsList.value[0];

      getAdminReport();
      
    };

    const toInt = ref(['Total Sales', 'Total Leads', 'New Sales', 'New Leads', 'Renewal Sales', 'Renewal Leads', 'Lost Lead Renewal Sales', 'Lost Lead Renewals', 'Comp', 'TPL', 'HL', 'RHL', 'WIP', 'Current Year Renewal Sales', 'Previous Year Renewal Leads', 'Untouched Customers', 'Untouched Renewal Leads', 'Untouched New Leads', 'Untouched LLR', 'Total Refund', 'Commission Paid To Agent', 'Partial Refund', 'Full Refund', 'Lost Leads', 'Lead Renewal Lost', 'Total Cancellation', 'Partial Cancellation', 'Full Cancellation'])

    const addComa = (e: any) => {
      if(!isNaN(e)) {
        return parseFloat(e).toLocaleString()
      } else {
        return e
      }
    }
    const loading = computed(() => {
      return store.getters.getARLoading;
    });
   
    return {
      loading,
      addComa,
      toInt,
      resetBodyParam,
      adminDashboardData,
      getAdminDashboardHeaders,
      yearsList,
      years,
      onYearSelect,
      searchByDate,
      exportReport,
      exportUrl,
      disableSaveButton,
      value2
    };
  },
  methods: {},
});
