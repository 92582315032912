
import { h, defineComponent, onMounted, ref, computed, getCurrentInstance, onUnmounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { ElDivider } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import CustomerService from "@/core/services/car/CustomerService";
import NotesService from "@/core/services/car/NotesService";
import TaskService from "@/core/services/car/TaskService";
import LeadList from "@/components/leads/Lead-List.vue";
import QuotesService from "@/core/services/car/QuotesService";
import CLExplore from "@/components/customers/Customer-Logs.vue";
import NotesExplore from "@/components/notes/List-Notes.vue";
import PolicyService from "@/core/services/car/PolicyService";
import SmsService from "@/core/services/car/SmsService";
import EmailService from "@/core/services/car/EmailService";
import LTExplore from "@/components/tasks/List-Task.vue";
import LeadService from "@/core/services/car/LeadService";
import LeadLogsExplore from "@/components/leads/Lead-Logs.vue";
// import LeadDocumentsExplore from "@/components/leads/Lead-Documents.vue";
import LeadDocumentsExplore from "@/components/leads/List-Lead-Documents.vue";
import ManualQuotesExplore from "@/components/manual-quotes/List-Manual-Quotes.vue";
import EmailExplore from "@/components/emails/List-Email.vue";
import UpdateEmailModal from "@/components/modals/forms/UpdateEmailModal.vue";
import UpdatePhoneNumberModal from "@/components/modals/forms/UpdatePhoneNumberModal.vue";
import SmsExplore from "@/components/sms/List-Sms.vue";
import { hideModal } from "@/core/helpers/dom";
import mainSocket from "./../../../main-socket";
import { UserTypesMixin } from "@/core/plugins/vcMixins";
import { updateModal, roles } from '@/store/stateless/store'
import UserService from "@/core/services/UserService";
import Multiselect from "@vueform/multiselect";
import UpdateOtherContactInfoModal from "@/components/modals/forms/UpdateOtherContactInfoModal.vue";
import EmptyLead from '@/views/car/customers/CustomerDetails/EmptyLead.vue'


interface CustomerLogsBodyParam {
  customer_id: any;
}
interface CustomerBodyParam {
  per_page: string;
  task_due_dates: string;
  customer_status_id: number;
  agent: any;
  lead_status_id: number;
  keyword: string;
  start_date: string;
  end_date: string;
}
interface TaskBodyParam {
  customer_id: number;
  lead_id: number;
  due_date: string;
  disposition_id: number;
  task_notes: string;
  agent: number;
  lost_lead_reason_id: number;
}
interface NotesBodyParam {
  lead_id: number;
}
interface QuotesBodyParam {
  customer_id: number;
  lead_id: number;
}
interface LeadStatusBodyParm {
  car_lead_id: number;
  customer_id: number;
}
interface LeadDocumentsBodyParm {
  lead_id: number;
}
interface ManualQuotesBodyParm {
  customer_id: number;
}
interface TransferLeadsParam {
  customer_ids: any;
  agent_id: string;
  notes: string;
}

export default defineComponent({
  name: "customer-details",
  components: {
    LeadList,
    CLExplore,
    NotesExplore,
    LeadLogsExplore,
    LeadDocumentsExplore,
    ManualQuotesExplore,
    EmailExplore,
    SmsExplore,
    UpdateEmailModal,
    UpdatePhoneNumberModal,
    LTExplore,
    Multiselect,
    UpdateOtherContactInfoModal,
    EmptyLead
  },
  data() {
    return {
      size: 0, //0
      spacer: h(ElDivider, { direction: "vertical" }),
    };
  },
    setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const leadList = ref()
    const transferLeadModalRef = ref<HTMLElement | null>(null);
  
    let bodyParameters = {} as CustomerBodyParam;
    let customerLogsBodyParam = {} as CustomerLogsBodyParam;
    let taskParameters = {} as TaskBodyParam;
    let notesBodyParam = {} as NotesBodyParam;
    let quotesBodyParam = {} as QuotesBodyParam;
    let manualQuotesBodyParam = {} as ManualQuotesBodyParm;

    let leadDocumentsBodyParm = {} as LeadDocumentsBodyParm;
    let leadStatusBodyParm = {} as LeadStatusBodyParm;
    let activeLeadId = ref<number>();
    let transferLeadsParam = {} as TransferLeadsParam;
    bodyParameters = {
      per_page: "50",
      task_due_dates: "all",
      customer_status_id: -1,
      agent: "-1",
      lead_status_id: -1,
      keyword: "",
      start_date: "",
      end_date: "",
    };
    quotesBodyParam = {
      customer_id: -1,
      lead_id: -1,
    };

    // let customerDetails : any;
    // let customerData = ref([]);
    const taskModalRef = ref()
    const getLeadTaskList = (customer_id, lead_id) => {
      taskParameters.customer_id = customer_id;
      taskParameters.lead_id = lead_id;
      TaskService.getLeadTaskList(taskParameters);

      taskModalRef.value.toFirst()
    };
    const notesModal = ref()
    const getLeadNotes = (lead_id) => {
      activeLeadId.value = lead_id;
      notesBodyParam.lead_id = lead_id;
      NotesService.getLeadNotesList(notesBodyParam);

      notesModal.value.toFirst()
    };
    const getDriverDetailsList = (customer_id, lead_id = -1, lead_status) => {
      if(![2, 10].includes(lead_status)) {
        quotesBodyParam.customer_id = customer_id;
        quotesBodyParam.lead_id = lead_id;

        Promise.all([QuotesService.getDriverDetailsList(quotesBodyParam)]).then(
          (data) => {
            console.log(data);
          }
        );
      }
    };
    const fetchDriverDetails = (driver_details_id, fetchQuote = true) => {
      if(fetchQuote) QuotesService.fetchQuote({ driver_details_id: driver_details_id });
      leadList.value.lddExplore.firstClick()
    };

    const getLeadPolicies = (customer_id, lead_id, lead_status) => {
      if(![2, 10].includes(lead_status)) {
        PolicyService.getLeadPolicies({
          lead_id: lead_id,
          customer_id: customer_id,
        });
      }
      leadList.value.plExlore.firstClick()
    };
    const documentModal = ref()
    const getLeadDocuments = (lead_id) => {
      activeLeadId.value = lead_id;
      leadDocumentsBodyParm.lead_id = lead_id;
      documentModal.value.toFirst()
      // PolicyService.getLeadDocuments(leadDocumentsBodyParm);
      PolicyService.getLeadActivePolicyDocuments(leadDocumentsBodyParm);
    };
    const getLeadLogs = (lead_id, customer_id) => {
      leadStatusBodyParm.car_lead_id = lead_id;
      leadStatusBodyParm.customer_id = customer_id;
      LeadService.getLeadActivityLogs(leadStatusBodyParm);
      LeadService.getLeadStatusLogs(leadStatusBodyParm);
    };

    const manualModal = ref()
    const getManualQuotes = (customer_id) => {
      activeLeadId.value = customer_id;
      manualQuotesBodyParam.customer_id = customer_id;
      QuotesService.getManualQuotes(manualQuotesBodyParam);

      manualModal.value.toFirst()
    };

    const getSmsList = (customer_id) => {
      SmsService.getSmsList({ customer_id: customer_id });
    };

    const emailFocus = ref()
    const showEmailListExplorer = ref(false)
    const getEmailList = (customer_id) => {
      showEmailListExplorer.value = true
      EmailService.getEmailList({ customer_id: customer_id });
      setTimeout(() => {
        // emailFocus.value.toFirst()
      }, 500);
    };

    const getCustomerLeadStatusCount = (store, bodyParameters, reload = true) => {
      bodyParameters.reload = reload
      CustomerService.getCustomerLeadStatusCount(store, bodyParameters);
    };
    onMounted(() => {
      if(!roles.customerdetails.includes(roles.userId())) window.location.href = '/#/404'

      
      MenuComponent.reinitialization();
      customerLogsBodyParam.customer_id = route.params.customerId;
      setCurrentPageBreadcrumbs("Customer Details", ["Leads & Tasks"]);
      Promise.all([
        CustomerService.getCustomerDetails(store, route.params.customerId),
      ]).then((data) => {
        const driverDetailsObj = store.getters.getLeadDetails;
        fetchDriverDetails(driverDetailsObj[0].lead_driver_detail.id);
      });

      getCustomerLeadStatusCount(store, bodyParameters);
      // QuotesService.getManufacturers();
      QuotesService.getCountries();
      const currentUserId = store.getters.currentUser.id;

      mainSocket
        .channel("notif-invoice-car-" + route.params.customerId)
        .listen("GlobalNotificationToAppEvent", (response) => {
            CustomerService.getCustomerDetails(store, route.params.customerId)
          .then((data) => {
            const driverDetailsObj = store.getters.getLeadDetails;
            fetchDriverDetails(driverDetailsObj[0].lead_driver_detail.id);
        })
      })

      mainSocket
        .channel("channel-" + currentUserId)
        .listen("CarLeadStatusSocketEvent", (response) => {
          if (response.lead_status) {
            getCustomerLeadStatusCount(store, bodyParameters, false);
          }
        })
      
    });

    onUnmounted(() => {
      store.commit('setDomStatus', false)
    })

    const getCustomerLogs = () => {
      CustomerService.getCustomerActivityLogs(customerLogsBodyParam);
      CustomerService.getCustomerStatusLogs(customerLogsBodyParam);
    };
    const showDom = computed(() => {
      return store.getters.getDomStatus;
    });
    const getNotesList = computed(() => {
      return store.getters.getNotesList;
    });
    const customerDetails = computed(() => {
      return store.getters.getSelectedCustomerDetails;
    });

    const goToListingPage = () => {
      router.push("/customers/list");
    };

    const removePlus = (e: any) => {
      return e.replace("+", "");
    };

    const userPermissions = computed(() => {
      return store.getters.getUserPermissions;
    })

    const lists = computed(() => {
      let data = [];
      const quickLead = [0]
      // const quickLead = [2, 9, 10]
      if(Array.isArray(store.getters.getLeadDetails)) {
        data = store.getters.getLeadDetails;
        // return data?.filter((lead: any) => lead.lead_status_id != 2);
        return data.map((val: any) => ({
          ...val,
          is_disable: !quickLead.includes(val.lead_status_id) ? false : true
        }))
      }
      return data;
    })

    const documentList = computed(() => {
      const data = store.getters.getLeadDetails
      const value = data.find(x => ![2, 9, 10].includes(x.lead_status_id))
      if(value) return value.id

      return 0
    })
    //, 9, 10
    const viewDetailsPoliciesDisabledQuicklead = computed(() => {
      const data = store.getters.getLeadDetails;
      // const quicklead = data.filter((x: any) => {
      //   return [2, 9, 10].includes(x.lead_status_id) ? true : false
      // })
      
      const quicklead = data.map((x: any) => {
        if(![2, 9, 10].includes(x.lead_status_id))
        {
          return 1;
        }
        else
        {
          return 0;
        }
      
      })
     

      //lists.value.length == 1
      // console.log('quicklead',quicklead)
      // console.log('quicklead 2',quicklead.includes(1))
      if(!quicklead.includes(1)) {
        return true;
      }
      return false
    })

    const current = computed(() => {
      return store.getters.currentUser
    })

    const disableViewDetailsAndPolicies = computed(() => {
      const data = store.getters.getLeadDetails;
      if(data.length == 1) {
        const customer_lead_status = data.filter((item) => {
          return [2, 10].includes(item.lead_status_id)
        })
        return customer_lead_status.length > 0 ? true : false
      } else {
        return false
      }
    })


    const inst = getCurrentInstance()

    const updatePhoneNumberModal = ref()
    const openModal = () => {
      CustomerService.setUpdatePhoneNumberModal(true);
      updatePhoneNumberModal.value.toFirst()
    }

    const updateModalRef = ref()

    const toFirst = () => {
      updateModalRef.value.toFirst()
    }

    const updateContactInfoModalRef = ref()

    const openFocusToFirst = () => {
      if(notOmMAs.value) updateContactInfoModalRef.value.toFirst()
    }

    let salesAgentList = computed(() => {
      return store.getters.getDomSalesAgent;
    });
    const salesAgent = ref({
      placeholder: "Please Select",
      value: null,
      options: salesAgentList,
      searchable: true,
      clearOnSelect: true
    });

    const getSkyeAgentList = (agent) => {
      // console.log("get sales agents list API commented");
      let isTranfer = (agent == loggedInUser.value.id) ? false : true;
      UserService.getDomSalesAgents({
        type: "Car",
        isArchived: true,
        isActive: true,
        isTransfer: isTranfer
      });
      toAgent()
      // CustomerService.getSkyeAgentList(store);
    };

    let sales_agent_id: any;
    const onSalesAgentChange = (event) => {
      sales_agent_id = event;
    };

    const toAgent = () => {
      setTimeout(() => {
        const agentRef:any = inst?.refs?.agentRef
        agentRef.focusSearch()
      }, 500);
    }

    const closeButton = ref()
    const notes = ref<string>("");
    const isRequired = ref<boolean>(false);
    const isClick = ref<boolean>(false);
    transferLeadsParam = {
      customer_ids: [],
      agent_id: "",
      notes: ""
    };


    const transferLeads = () => {
      isRequired.value = false;

      if (!salesAgent.value.value) {
        isRequired.value = true;
      } else {
        isClick.value = true;
        sales_agent_id;
        // checkedCustomers.value.forEach(function (customer) {
        // let id =  parseInt(route.params.customerId)
        transferLeadsParam.customer_ids.push(route.params.customerId);
        // });
        transferLeadsParam.agent_id = sales_agent_id;
        transferLeadsParam.notes = notes.value;
        
        CustomerService.transferLeads(store, transferLeadsParam)
          .then(() => {
            isClick.value = false;
            
            salesAgent.value.value = null;
            // transferLeadsParam.customer_ids = [];
            transferLeadsParam.agent_id = "";
            transferLeadsParam.notes = "";
            notes.value = "";
            closeButton.value.click()
         

            CustomerService.getCustomerDetailsNoRefresh(
              store,
              route.params.customerId
            );
            
            
          })
          .catch((error) => {
            isClick.value = false;
            // salesAgent.value.value = null;
            // transferLeadsParam.agent_id = "";
            // transferLeadsParam.notes = "";
          });
      }
    };
    
    const resetLeadTransferModal = () => {
      isClick.value = false;
      salesAgent.value.value = null;
      // transferLeadsParam.customer_ids = [];
      transferLeadsParam.agent_id = "";
      transferLeadsParam.notes = "";
      notes.value = "";
    }

    const loggedInUser = computed(() => {
      const current = window.localStorage.getItem('user');
      return JSON.parse(current || '');
    });

    const notOmMAs = computed(() => {
      const lsUser = window.localStorage.getItem('user')
      if(lsUser) {
        const user = JSON.parse(lsUser)
        if(![7, 11, 15].includes(user.role_id)) return true
      }

      return false
    })

    const notMAs = computed(() => {
      const lsUser = window.localStorage.getItem('user')
      if(lsUser) {
        const user = JSON.parse(lsUser)
        if(![7, 11].includes(user.role_id)) return true
      }

      return false
    })

    return {
      showEmailListExplorer,
      notMAs,
      notOmMAs,
      leadList,
      documentList,
      openFocusToFirst,
      updateContactInfoModalRef,
      notes,
      closeButton,
      resetLeadTransferModal,
      loggedInUser,
      isClick,
      transferLeads,
      onSalesAgentChange,
      toAgent,
      getSkyeAgentList,
      salesAgent,
      documentModal,
      manualModal,
      notesModal,
      taskModalRef,
      emailFocus,
      updateModalRef,
      updatePhoneNumberModal,
      toFirst,
      roles,
      openModal,
      updateModal,
      viewDetailsPoliciesDisabledQuicklead,
      current,
      lists,
      removePlus,
      UserTypesMixin,
      goToListingPage,
      getCustomerLogs,
      getLeadTaskList,
      getLeadNotes,
      getDriverDetailsList,
      activeLeadId,
      customerDetails,
      showDom,
      getLeadPolicies,
      getNotesList,
      getLeadLogs,
      getLeadDocuments,
      getManualQuotes,
      getSmsList,
      getEmailList,
      fetchDriverDetails,
      userPermissions,
    };
  },
});
